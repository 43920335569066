<template>
	<div>
		<a-tabs default-active-key="1">
			<a-tab-pane key="1" :tab="$t('systemModule.users.edit.title')" force-render>
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.edit.names')">
								<a-input v-decorator="[
										'name',
										{
											rules: requiredFileds,
										},
									]" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.edit.surname')">
								<a-input v-decorator="[
										'surname',
										{
											rules: requiredFileds,
										},
									]" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.tableHeaders.email')">
								<a-input v-decorator="[
										'email',
										{
											rules: requiredFileds,
										},
									]" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.tableHeaders.phone')">
								<a-input v-decorator="[
										'phone',
										{
											rules: requiredFileds,
										},
									]" autocomplete="off" v-mask="'##########'" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.tableHeaders.role')">
								<a-select placeholder="Selecciona" v-decorator="['role']">
									<a-select-option :value="key" v-for="(role, key) in roles" :key="key">{{ role.label }}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.edit.password')">
								<a-input-password v-decorator="[
										'password',
										{
											rules: passwordFiled,
										},
									]" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<a-form-item :label="$t('systemModule.users.edit.confirmPassword')">
								<a-input-password @blur="handleConfirmBlur" v-decorator="[
										'confirmPassword',
										{
											rules: confirmPassword,
										},
									]" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-sm-6 col-md-4 col-lg-3">
							<div :class="$style.btnPasswordGenerator">
								<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">{{ $t('systemModule.users.edit.generatePassword') }}</a-button>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button icon="save" class="btn btn-success" htmlType="submit">{{ $t('general.save') }}</a-button>
						</div>
					</div>
				</a-form>
			</a-tab-pane>
			<!-- <a-tab-pane key="2" tab="Permisos" force-render>
				<usersPermissions />
			</a-tab-pane> -->
		</a-tabs>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
// import usersPermissions from '@/components/users/permissions'
import { roles } from '@/constants'

export default {
	name: 'usersDetail',
	components: {
		// usersPermissions,
	},
	directives: {
		mask,
	},
	data() {
		return {
			roles,
			form: this.$form.createForm(this),
		}
	},
	mounted() {
		let selectedAreas = []
		if (utilities.objectValidate(this.actualUser, 'areas', false)) {
			selectedAreas = this.actualUser.areas.map((element) => {
				return element.id
			})
		}
		this.form.setFieldsValue({
			name: utilities.objectValidate(this.actualUser, 'name', ''),
			surname: utilities.objectValidate(this.actualUser, 'surname', ''),
			email: utilities.objectValidate(this.actualUser, 'email', ''),
			phone: utilities.objectValidate(this.actualUser, 'phone', ''),
			role: utilities.objectValidate(this.actualUser, 'role', ''),
		})
	},
	computed: {
		...mapGetters('users', ['usersList', 'actualUser']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
		passwordFiled() {
			let rules = [
				{
					min: 6,
					message: this.$t('systemModule.users.edit.passwordMsg'),
				},
				{
					validator: this.validateToNextPassword,
				},
			]
			return rules
		},
		confirmPassword() {
			let rules = [
				{
					min: 6,
					message: this.$t('systemModule.users.edit.passwordMsg'),
				},
				{
					validator: this.compareToFirstPassword,
				},
			]
			return rules
		},
	},

	methods: {
		onCancel() {
			this.$router.replace('/config/users')
		},
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.form.setFieldsValue({ password: passwd, confirmPassword: passwd })
			})
		},
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback(this.$t('systemModule.users.edit.confirmPasswordMsg'))
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
					}
					delete payload.confirmPassword

					if (this.isNewRecord) {
						if (values.password == '') {
							Swal.fire({
								title: this.$t('general.deleteMessage.title'),
								text: this.$t('systemModule.users.edit.setPassword'),
								icon: 'warning',
							})
							return false
						}

						this.$store.dispatch('users/CREATE', payload)
					} else {
						payload.id = this.$route.params.id
						this.$store.dispatch('users/UPDATE', { payload, redirect: false })
					}
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>