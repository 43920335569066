<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<usersDetail />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import usersDetail from '@/components/users/detail'

export default {
	name: 'usersDetailView',
	components: {
		usersDetail,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('users', ['usersList', 'spinnerLoader', 'spinnerLoaderLabel']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
	},
	mounted() {
		if (this.isNewRecord) {
			//
		} else {
			//
		}
	},
}
</script>