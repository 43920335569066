var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":_vm.$t('systemModule.users.edit.title'),"force-render":""}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.edit.names')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'name',
									{
										rules: _vm.requiredFileds,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'name',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.edit.surname')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'surname',
									{
										rules: _vm.requiredFileds,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'surname',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}]})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.tableHeaders.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'email',
									{
										rules: _vm.requiredFileds,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.tableHeaders.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'phone',
									{
										rules: _vm.requiredFileds,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'phone',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.tableHeaders.role')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['role']),expression:"['role']"}],attrs:{"placeholder":"Selecciona"}},_vm._l((_vm.roles),function(role,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(role.label))])}),1)],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.edit.password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{
										rules: _vm.passwordFiled,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: passwordFiled,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}]})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('a-form-item',{attrs:{"label":_vm.$t('systemModule.users.edit.confirmPassword')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'confirmPassword',
									{
										rules: _vm.confirmPassword,
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'confirmPassword',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: confirmPassword,\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"},on:{"blur":_vm.handleConfirmBlur}})],1)],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('div',{class:_vm.$style.btnPasswordGenerator},[_c('a-button',{staticClass:"mr-1 btn btn-info btn-block",attrs:{"icon":"lock"},on:{"click":_vm.onGeneratePassword}},[_vm._v(_vm._s(_vm.$t('systemModule.users.edit.generatePassword')))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 text-left"},[_c('a-button',{staticClass:"btn btn-warning",attrs:{"icon":"arrow-left"},on:{"click":_vm.onCancel}},[_vm._v(_vm._s(_vm.$t('general.back')))])],1),_c('div',{staticClass:"col-md-8 text-right"},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"save","htmlType":"submit"}},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }